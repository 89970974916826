import { Check } from 'lucide-react'

export default function Pricing() {
  return (
    <div className="relative w-full px-4">
      <div className="hidden md:block absolute -left-44 top-1/2 -translate-y-1/2 rounded-[633px] border border-black bg-[#4928CE] opacity-50 blur-[150px] w-[500px] h-[500px]"></div>
      <div className="mx-auto max-w-4xl text-center z-20">
        <h2 className="mb-4 text-4xl font-bold text-white sm:text-5xl">
          Start your free trial today
        </h2>
        <p className="mb-2 text-lg text-gray-300">
          Try it out for 3 days, risk-free.
        </p>
        <p className="mb-12 text-sm text-gray-400">Terms & conditions apply.</p>

        {/* Pricing Card */}
        <div className="mx-auto max-w-sm rounded-3xl border border-white-700  p-8">
          <h3 className="mb-6 text-2xl font-bold italic text-white">
            First 3 days FREE
          </h3>

          <ul className="mb-8 space-y-4">
            <li className="flex items-center text-left text-gray-300">
              <Check className="mr-3 h-5 w-5 text-white" />
              3-day free trial
            </li>
            <li className="flex items-center text-left text-gray-300">
              <Check className="mr-3 h-5 w-5 text-white" />
              50% off your first month
            </li>
            <li className="flex items-center text-left text-gray-300">
              <Check className="mr-3 h-5 w-5 text-white" />
              $20/month
            </li>
            <li className="flex items-center text-left text-gray-300">
              <Check className="mr-3 h-5 w-5 text-white" />
              other benefits
            </li>
          </ul>

          <button className="w-full rounded-full bg-indigo-600 px-6 py-3 font-semibold text-white transition-colors hover:bg-indigo-700">
            Try it free
          </button>
        </div>
      </div>
    </div>
  )
}
