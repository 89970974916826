import TestimonialCard from '@/components/Landing/TestimonialCard'

export default function TimeLine() {
  return (
    <div className="w-full px-4 py-24 ">
      <div className="mx-auto max-w-6xl">
        {/* Timeline Container */}
        <div className="relative">
          {/* Vertical Line */}
          <div className="absolute left-1/2 top-0 h-full w-px -translate-x-1/2 bg-gray-700" />

          {/* Timeline Sections */}
          <div className="space-y-24">
            {/* Section 1: Explore */}
            <div className="relative grid grid-cols-1 gap-24 md:grid-cols-2">
              <div className="flex flex-col justify-center">
                <h2 className="mb-4 text-4xl font-bold text-white">
                  Explore restaurants, worry-free
                </h2>
                <p className="mb-2 text-lg text-gray-300">
                  We support{' '}
                  <span className="font-bold">
                    1,000+ dietary restrictions and allergens
                  </span>
                  , ranging from Alpha Gal Syndrome to Xanthan Gum.
                </p>
                <button className="mt-4 w-fit rounded-full border-2 border-white px-6 py-2 text-white hover:bg-gray-700">
                  Get Started
                </button>
                <TestimonialCard
                  author={'DYLAN T.'}
                  quote={
                    'Being able to see portion size before we order is the GOAT.'
                  }
                  location={'New York, NY'}
                />
              </div>
              <div className="relative">
                <div className="absolute -right-44 top-1/2 -translate-y-1/2 rounded-[633px] border border-black bg-[#4928CE] opacity-50 blur-[150px] w-[500px] h-[500px]"></div>
                <BrowserMockup>
                  <img
                    src="https://placehold.co/600x400"
                    alt="Restaurant search"
                    className="w-full rounded-lg"
                  />
                </BrowserMockup>
              </div>
              <TimelineDot />
            </div>

            {/* Section 2: Photos */}
            <div className="relative grid grid-cols-1 gap-24 md:grid-cols-2">
              <div className="relative order-2 md:order-1">
                <div className="absolute -left-44 top-1/2 -translate-y-1/2 rounded-[633px] border border-black bg-[#4928CE] opacity-50 blur-[150px] w-[500px] h-[500px]"></div>
                <BrowserMockup>
                  <img
                    src="https://placehold.co/600x400"
                    alt="Restaurant details page"
                    className="w-full rounded-lg"
                  />
                </BrowserMockup>
              </div>
              <div className="order-1 flex flex-col justify-center md:order-2">
                <h2 className="mb-4 text-4xl font-bold text-white">
                  See photos upfront
                </h2>
                <p className="mb-2 text-lg text-gray-300">
                  No more surprises - browse a vast trove of pictures from
                  previous customers.
                </p>
                <button className="mt-4 w-fit rounded-full border-2 border-white  px-6 py-2 text-white hover:bg-gray-700">
                  I&apos;m Ready
                </button>
                <TestimonialCard
                  author={'CHUCK C.'}
                  quote={
                    'Found a restaurant in an industrial area. I thought this must be an error. But after digging deeply into Google, I found it looked real after all. I went there with my son, and had a great meal at a new spot! Never would have found it without YumDodo!'
                  }
                  location={'Woodinville, WA'}
                />
              </div>
              <TimelineDot />
            </div>

            {/* Section 3: Find Meals */}
            <div className="relative grid grid-cols-1 gap-24 md:grid-cols-2">
              <div className="flex flex-col justify-center">
                <h2 className="mb-4 text-4xl font-bold text-white">
                  Find meals you can enjoy
                </h2>
                <ul className="mb-4 space-y-2 text-lg text-gray-300">
                  <li>500,000+ restaurants</li>
                  <li>60,000,000+ meals</li>
                  <li>100,000,000+ photos</li>
                </ul>
                <p className="mb-4 text-sm italic text-gray-400">
                  More countries coming soon!
                </p>
                <button className="mt-4 w-fit rounded-full border-2 border-white px-6 py-2 text-white hover:bg-gray-700">
                  Let&apos;s Go
                </button>
                <TestimonialCard
                  author={'MIGUEL H.'}
                  quote={`Despite my strict diet, I'm finding all these new restaurants near me I didn't even know existed!`}
                  location={'Rogers, AR'}
                />
              </div>
              <div className="relative">
                <div className="absolute -right-44 top-1/2 -translate-y-1/2 rounded-[633px] border border-black bg-[#4928CE] opacity-50 blur-[150px] w-[500px] h-[500px]"></div>
                <BrowserMockup>
                  <img
                    src="https://placehold.co/600x400"
                    alt="Restaurant search interface with map"
                    className="w-full rounded-lg"
                  />
                </BrowserMockup>
              </div>
              <TimelineDot />
            </div>

            <div className="relative grid grid-cols-1 gap-8 md:grid-cols-2">
              <TimelineDot />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function TimelineDot() {
  return (
    <div className="hidden md:block absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white" />
  )
}

function BrowserMockup({ children }: { children: React.ReactNode }) {
  return (
    <div className="relative overflow-hidden rounded-xl bg-gray-800 shadow-2xl">
      <div className="flex items-center gap-1.5 border-b border-gray-700 bg-white px-4 py-3">
        <div className="flex gap-1.5">
          <div className="h-2.5 w-2.5 rounded-full bg-red-600" />
          <div className="h-2.5 w-2.5 rounded-full bg-yellow-600" />
          <div className="h-2.5 w-2.5 rounded-full bg-green-600" />
        </div>
      </div>
      <div>{children}</div>
    </div>
  )
}
