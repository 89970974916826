import React from 'react'

interface TestimonialCardProps {
  quote: string
  author: string
  location: string
}

export default function TestimonialCard({
  quote,
  author,
  location,
}: TestimonialCardProps) {
  return (
    <div className="pt-8 rounded-xl max-w-2xl">
      <blockquote className="space-y-6">
        <p className="text-white text-xl font-medium italic leading-relaxed">
          &#34;{quote}&#34;
        </p>
        <footer className="flex items-center gap-4 pt-2">
          <div className="h-16 w-16 rounded-full bg-gray-200" />
          <div className="flex flex-col">
            <cite className="text-white text-base not-italic">{author}</cite>
            <span className="text-white text-base">{location}</span>
          </div>
        </footer>
      </blockquote>
    </div>
  )
}
