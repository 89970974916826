import React, { useState, KeyboardEvent } from 'react'
import axios from 'axios'

const NewsLetter: React.FC = () => {
  const [email, setEmail] = useState<string>('')
  const [msg, setMsg] = useState<string | null>(null)

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSubmit = async (): Promise<void> => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (email.trim().length === 0) {
      setMsg('Enter an email')
      return
    }
    if (!emailPattern.test(email)) {
      setMsg('Invalid Email. Please try again.')
      return
    }
    try {
      const res = await axios.post('/newsletter/signup', {
        email,
        campaign: 'newsletter',
      })
      setMsg('Successfully subscribed! 🎉')
    } catch (error) {
      setMsg('Failed to subscribe. Please try again.')
    }
  }

  return (
    <>
      <div className="my-4 text-white text-center">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6"
          >
            Join our Newsletter
          </label>
          <div className="relative mt-2 flex items-center">
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onKeyDown={handleKeyDown}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="panda@gmail.com"
              className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
              <kbd
                onClick={handleSubmit}
                className="bg-black inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs hover:bg-blue-600 text-white cursor-pointer"
              >
                Subscribe
              </kbd>
            </div>
          </div>
        </div>
      </div>
      {msg && <div className="mt-2 text-sm">{msg}</div>}
    </>
  )
}

export default NewsLetter
