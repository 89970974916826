import Link from 'next/link'

export default function Footer() {
  return (
    <footer className="bg-[#1C1B1B] text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex justify-center space-x-6 mb-6">
          {/* TikTok Icon */}
          <a
            href="https://www.tiktok.com/@yumdodoeats"
            className="hover:opacity-75 transition-opacity"
            target="_blank"
          >
            <img
              className="w-6 fill-currentstroke-white dark:rounded-lg"
              src={'/assets/social/tiktok/white.svg'}
              alt={'instagram'}
            />
          </a>

          {/* Pinterest Icon */}
          <a
            href="#"
            className="hover:opacity-75 transition-opacity"
            target="_blank"
          >
            <img
              className="w-6 fill-currentstroke-white dark:rounded-lg"
              src={'/assets/social/pinterest/white.svg'}
              alt={'instagram'}
            />
          </a>

          {/* Facebook Icon */}
          <a
            href="#"
            className="hover:opacity-75 transition-opacity"
            target="_blank"
          >
            <img
              className="w-6 fill-currentstroke-white dark:rounded-lg"
              src={'/assets/social/facebook/white.svg'}
              alt={'instagram'}
            />
          </a>

          {/* Instagram Icon */}
          <a
            href="https://www.instagram.com/yumdodoeats/"
            className="hover:opacity-75 transition-opacity"
            target="_blank"
          >
            <img
              className="w-6 fill-currentstroke-white dark:rounded-lg"
              src={'/assets/social/instagram/white.svg'}
              alt={'instagram'}
            />
          </a>
        </div>

        <div className="flex justify-center items-center space-x-4 text-sm">
          <span>© 2024 YumDodo</span>
          <span className="text-gray-400">|</span>
          <Link href="/terms-of-service/" className="hover:underline">
            Terms of Service
          </Link>
          <span className="text-gray-400">|</span>
          <Link href="/privacy-policy/" className="hover:underline">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  )
}
